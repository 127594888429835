import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useReducer, createContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from '@/layouts/Head/Head'
import Footer from '@/layouts/Foot/Foot'
import Blog from '@/views/Blog/Blog'
import Forum from '@/views/Forum/Forum'
import Detail from '@/components/Detail/Detail'
import Editor from '@/components/Editor/Editor'
import Search from '@/components/Search/Search'
import User from '@/views/User/User'
import Msg from '@/views/Msg/Msg'
import MsgSystem from '@/views/Msg/MsgSystem'
import MsgDetail from '@/views/Msg/MsgDetail/MsgDetail'
import MsgDetailSystem from '@/views/Msg/MsgDetail/MsgDetailSystem'

import { reducer, userInfo } from '@/reducer/reducer'

export const Context = createContext(null)

function App() {
  const [state, dispatch] = useReducer(reducer, userInfo)

  return (
    <>
      <Router>
        <Context.Provider value={{ state, dispatch }}>
          <Header />
          <main className='py-3'>
            <Container className='main'>
              <Switch>
                <Route path='/blog'>
                  <Blog />
                </Route>
                <Route path='/forum'>
                  <Forum />
                </Route>
                <Route path='/detail/:id'>
                  <Detail />
                </Route>
                <Route path='/editor'>
                  <Editor />
                </Route>
                <Route path='/search/:keywords'>
                  <Search />
                </Route>
                <Route path='/user'>
                  <User />
                </Route>
                <Route path='/msg/comm'>
                  <Msg />
                </Route>
                <Route path='/msg/system'>
                  <MsgSystem />
                </Route>
                <Route path='/msg'>
                  <Redirect to='/msg/comm' />
                </Route>
                <Route path='/msginfo/:id'>
                  <MsgDetailSystem />
                </Route>
                <Route path='/msginfod/:id'>
                  <MsgDetail />
                </Route>
                <Redirect to={'/blog'} />
              </Switch>
            </Container>
          </main>
          <Footer />
        </Context.Provider>
      </Router>
    </>
  )
}

export default App;
