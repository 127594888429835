import React, { useState, useEffect, useRef, createContext } from 'react'
import { Nav, Navbar, Form, Button } from 'react-bootstrap'
import { BrowserRouter as Router, Route, useRouteMatch, Switch, useHistory, NavLink } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Ideas from './Ideas/Ideas'
import Signin from '@/layouts/Signin/Signin'

import './Forum.css';
import 'react-datepicker/dist/react-datepicker.css';

import { getGoodsSku } from '@/utils/axios'

export const HeatContext = createContext()

const Forum = () => {
  let history = useHistory()
  let token = localStorage.getItem('token')
  const { path } = useRouteMatch()
  const match = useRouteMatch('/forum/discuss')
  const [modalShow, setModalShow] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [sku, setSku] = useState([])
  const [queryparams, setQueeryparams] = useState({
    heat: 0,
    goodtype: 0,
    ctime: 0
  })
  const childRef = useRef()

  // 产品SKU
  const getSku = () => {
    getGoodsSku().then(res => {
      if (res.code === 1) {
        setSku(res.msg)
      }
    })
  }

  const onChangeHeat = (value) => {
    setQueeryparams({
      heat: value,
      goodtype: queryparams.goodtype,
      ctime: queryparams.ctime
    })
  }

  const onChangeGoodtype = (value) => {
    setQueeryparams({
      heat: queryparams.heat,
      goodtype: value,
      ctime: queryparams.ctime
    })
  }

  useEffect(() => {
    getSku()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const linkChange = (url) => {
    history.push(url)
  }

  // 时间筛选
  const onClickTime = () => {
    let qtime = moment(startDate).format("YYYY-MM-DD")
    setQueeryparams({
      heat: queryparams.heat,
      goodtype: queryparams.goodtype,
      ctime: qtime
    })
    childRef.current.onQueryData(qtime)
  }

  // 新建话题
  const createEditor = () => {
    if (!token) {
      setModalShow(true)
      return
    }
    history.push('/editor')
  }

  return (
    <>
      <Router>
        <div className='container_wrapper forum'>
          <Navbar>
            <Nav className='froum_navbar_nav'>
              <Nav.Item>
                <NavLink to='/forum' exact activeClassName='active' className={'nav-link'} onClick={() => linkChange('/forum')}>Product discussion</NavLink>
              </Nav.Item>
              <Nav.Item className='ml-5'>
                <NavLink to='/forum/discuss' activeClassName='active' className={'nav-link'} onClick={() => linkChange('/forum/discuss')}> Ideas and suggestions</NavLink>
              </Nav.Item>
            </Nav>
            <Form inline>
              <Button size='sm' onClick={createEditor}><Nav.Link>Release new topic</Nav.Link></Button>
            </Form>
          </Navbar>
          <Form inline>
            {
              match === null &&
              <>
                <Form.Label className='my-1 mr-2'>
                  Product:
                </Form.Label>
                <Form.Control
                  as='select'
                  className='my-1 mr-sm-2'
                  size='sm'
                  custom
                  value={queryparams.goodtype}
                  onChange={value => onChangeGoodtype(value.target.options[value.target.options.selectedIndex].value)}
                >
                  {
                    sku.map((sku) => (
                      <option value={sku.id} key={sku.id}>{sku.goods_unit}</option>
                    ))
                  }
                  <option value='0'>All</option>
                </Form.Control>
              </>
            }
            <Form.Label className='my-1 mr-2 ml-3'>
              Top:
            </Form.Label>
            <Form.Control
              as='select'
              className='my-1 mr-sm-2'
              size='sm'
              custom
              value={queryparams.heat}
              onChange={value => onChangeHeat(value.target.options[value.target.options.selectedIndex].value)}
            >
              <option value='1'>view</option>
              <option value='2'>like</option>
              <option value='3'>comment</option>
              <option value='0'>all</option>
            </Form.Control>
            <Form.Label className='my-1 mr-2 ml-3' htmlFor='date'>
              Time:
            </Form.Label>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
            <Button size='sm' className='screening_btn' onClick={onClickTime}>Filter</Button>
          </Form>
        </div>
        <main className='py-3'>
          <Switch>
            <Route exact path={path}>
              <HeatContext.Provider value={queryparams}><Ideas ref={childRef} /></HeatContext.Provider>
            </Route>
            <Route path={`${path}/discuss`}>
              <HeatContext.Provider value={queryparams}><Ideas ref={childRef} /></HeatContext.Provider>
            </Route>
          </Switch>
        </main>
      </Router>
      <Signin show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Forum