//GoogleSign.js
/**
 * 初始化谷歌登录
 * @param opts
 * @param success
 * @param error
 */
function onInitGoogle(opts, success, error) {
  // let getAuthInstance = {};
  let baseOptions = {
    client_id: '560925323400-bv661391007mmnvasb5d5vgltpgthhj7',
    cookiepolicy: '7luDFqh7DJd4MNax23MccQmw'
  }
  let options = { ...baseOptions, ...opts }
  window.gapi.load('auth2', function () {
    window.gapi.auth2.init(options).then(res => {
      success(res)
    }, err => {
      error(err)
    })
  })
}

/**
 * 初始化谷歌的getAuthInstance
 * GoogleAuth 是一个单例类，提供允许用户使用Google帐户登录，获取用户当前登录状态，从用户的Google个人资料中获取特定数据，请求其他范围以及从当前帐户注销的方法。
 * @returns {*}
 */
function getAuthInstance() {
  return window.gapi.auth2.getAuthInstance()
}

/**
 *google登录
 */
function googleSignIn(success, error) {
  window.getAuthInstance.signIn().then(function (res) {
    success(res)
  }, function (err) {
    error(err)
  });
}

/**
 * google登出
 */
function googleSignOut(success, error) {
  window.getAuthInstance.signOut().then(function () {
    success()
  }, function (err) {
    error(err)
  });
}

const GooleSignLogin = {
  onInitGoogle,
  getAuthInstance,
  googleSignIn,
  googleSignOut
};

export default GooleSignLogin