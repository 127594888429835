import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { message, Upload } from 'antd'
import Wangeditor from 'wangeditor'
import { useHistory } from "react-router-dom"

import './Editor.css';

import { getGoodsSku, upload, addCommunity } from '@/utils/axios'
const Editor = () => {
  let history = useHistory()

  const [content, setContent] = useState('')
  const [skulist, setSkulist] = useState([])
  const titleRef = useRef('')
  const [type, setType] = useState(0)
  const [sku, setSku] = useState(0)
  const [upimg, setUpimg] = useState('')

  // 产品SKU
  const getSku = () => {
    getGoodsSku().then(res => {
      if (res.code === 1) {
        setSkulist(res.msg)
      }
    })
  }

  const onChangeType = (type) => {
    setType(Number(type))
  }

  const onChangeSku = (sku) => {
    setSku(Number(sku))
  }

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      history.push('/')
    }
  })

  useEffect(() => {
    getSku()

    let editor = new Wangeditor(document.getElementById('editor_wrap'))
    editor.config.placeholder = 'Please enter what you want to post'
    editor.config.excludeMenus = ['video', 'code', 'todo', 'emoticon']
    editor.config.showLinkImg = false

    // 富文本上传图片
    editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      let formData = new FormData()
      formData.append('file', resultFiles[0])
      upload(formData)
        .then((res) => {
          if (res.code === 1) {
            message.success(res.msg)
            insertImgFn(res.data.src)
          } else {
            message.error(res.msg)
          }
        })
        .catch((err) => {
          message.error(err)
        })
    }

    editor.config.onchange = (newHtml) => {
      setContent(newHtml)
    }
    editor.create()

  }, [])


  // 
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  // 上传图片
  const handleUpload = (file) => {
    let formData = new FormData()
    formData.append('file', file.file)
    upload(formData)
      .then((res) => {
        if (res.code === 1) {
          message.success(res.msg)
          setUpimg(res.data.src)
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  // 发帖
  const postArticle = () => {
    let title = titleRef.current.value

    if (!title.trim()) {
      message.warning('Please enter subject!')
      return
    }
    if (type === 0) {
      message.warning('Please choose the category!')
      return
    }

    if (type === 1 && sku === 0) {
      message.warning('Please select product SKU!')
      return
    }

    if (!content.trim()) {
      message.warning('Please enter what you want to post!')
      return
    }

    let params = {
      title,
      category: type,
      goods_id: sku,
      pic: upimg,
      content
    }

    addCommunity(params).then(res => {
      if (res.code === 1) {
        message.success(res.msg)
        setTimeout(() => {
          history.push('/forum')
        }, 1500)
      } else {
        message.error(res.msg)
      }
    })
  }

  // 清空
  const reset = () => {
    setContent('')
    setType(0)
    setUpimg('')
    titleRef.current.value = ''
    document.querySelector('.w-e-text').innerHTML = '<p data-we-empty-p><br ></p>'
  }

  return (
    <>
      <div className='py-3 editor_page'>
        <Form>
          <Form.Group as={Row}>
            <Col md={4}>
              <Form.Control
                size='lg'
                placeholder='Please enter subject'
                className='title'
                ref={titleRef}
                required
              />
            </Col>
            <Col md={4}>
              <Form.Control
                as='select'
                size='lg'
                custom
                value={type}
                onChange={value => onChangeType(value.target.options[value.target.options.selectedIndex].value)}
              >
                <option value='0' disabled>--Please choose the category--</option>
                <option value='1'>Product discussion</option>
                <option value='2'>Ideas and suggestions</option>
              </Form.Control>
            </Col>
            {
              type === 1 &&
              <Col md={4}>
                <Form.Control
                  as='select'
                  size='lg'
                  custom
                  value={sku}
                  onChange={value => onChangeSku(value.target.options[value.target.options.selectedIndex].value)}
                >
                  <option value='0' disabled>--Please select product SKU--</option>
                  {
                    skulist.map((sk) => (
                      <option value={sk.id} key={sk.id}>{sk.goods_unit}</option>
                    ))
                  }
                </Form.Control>
              </Col>
            }
          </Form.Group>
          <Form.Group as={Row} className='py-3'>
            <Col md='2'>
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={handleUpload}
              >
                {upimg ? <img src={upimg} alt='avatar' style={{ width: '90%' }} /> : '+'}
              </Upload>
              <p>Only supports .jpg/.png format</p>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='py-3'>
            <Col>
              <div className='editor_wrap' id='editor_wrap'></div>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col>
              <Button variant='primary' size='lg' onClick={postArticle}>Pulish</Button>
              <Button variant='outline-secondary' size='lg' className='ml-4' onClick={reset}>Clear</Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}

export default Editor