import React from 'react'
import { Container, Row, Col, Image, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './Foot.css';

import LogoImg from '@/assets/images/logo1.png';
import FacebookImgD from '@/assets/images/face_ico.png';
import FacebookImgA from '@/assets/images/face_ico_active.png';
import SocialImgD from '@/assets/images/social_ico.png';
import SocialImgA from '@/assets/images/social_ico_active.png';
import TwitterImgD from '@/assets/images/twitter_ico.png';
import TwitterImgA from '@/assets/images/twitter_ico_active.png';
import YoutubeImgD from '@/assets/images/youtube_ico.png';
import YoutubeImgA from '@/assets/images/youtube_ico_active.png';


const Footer = () => {
  return (
    <div className='footer'>
      <Container>
        <div className='footer_top'>
          <Row>
            <Col xs={{ span: 4, offset: 4 }} md={{ span: 2, offset: 0 }}>
              <Link to='/'><Image src={LogoImg} /></Link>
            </Col>
            <Col xs={12} md={5}>
              <p>Email：bengooservice@outlook.com</p>
            </Col>
          </Row>
        </div>
        <div className='footer_mid'>
          <Row>
            <Col xs={12} lg={8} xl={9}>
              <p>Copyright  2017-2021 Bengoo Inc. All Rights Reserved</p>
            </Col>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} md={{ span: 5, offset: 4 }} lg={{ span: 4, offset: 0 }} xl={3} >
              <Row>
                <Col>
                  <Nav.Link href='https://twitter.com/bengoo_official' target='_blank' rel="noreferrer">
                    <Image className='default' src={TwitterImgD} />
                    <Image className='active' src={TwitterImgA} />
                  </Nav.Link>
                </Col>
                <Col>
                  <Nav.Link href='https://www.facebook.com/bengoofans' target='_blank' rel="noreferrer">
                    <Image className='default' src={FacebookImgD} />
                    <Image className='active' src={FacebookImgA} />
                  </Nav.Link>
                </Col>
                <Col>
                  <Nav.Link href='https://www.youtube.com/channel/UCEog3HvUxdUuGiMgbsWpo4A' target='_blank' rel="noreferrer">
                    <Image className='default' src={YoutubeImgD} />
                    <Image className='active' src={YoutubeImgA} />
                  </Nav.Link>
                </Col>
                <Col>
                  <Nav.Link href='https://www.instagram.com/bengooofficial' target='_blank' rel="noreferrer">
                    <Image className='default' src={SocialImgD} />
                    <Image className='active' src={SocialImgA} />
                  </Nav.Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='footer_bottom'>
          <p className='text-center beian'><a href='https://www.beian.miit.gov.cn/' target='_blank' rel="noreferrer" >粤ICP备2021004012号</a></p>
        </div>
      </Container>
    </div>
  )
}

export default Footer