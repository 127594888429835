import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, ListGroup, Image, Nav, Badge, Modal } from 'react-bootstrap'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import DatePicker from 'react-datepicker'
import { Empty, message } from 'antd'
import throttle from 'lodash/throttle'
import { useHistory } from "react-router-dom"

import './User.css';

import { getGoodsSku, base, communityPraise, communityCancelPraise, deleteCommunity } from '@/utils/axios'

const User = () => {
  // let token = localStorage.getItem('token')
  let history = useHistory()
  let page = useRef(1)
  let flashNum = useRef(0)
  const [modalShow, setModalShow] = useState(false)
  const [sku, setSku] = useState([])
  let [searchlist, setSearchlist] = useState([])
  let [artcid, setArtcid] = useState(0)
  let [artcsort, setArtcsort] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [queryparams, setQueeryparams] = useState({
    heat: 0,
    goodtype: 0,
    ctime: 0
  })

  // 初始化
  const getBase = () => {
    page.current = 1
    base({ self: 1, heat: queryparams.heat, goods_id: queryparams.goodtype, ctime: queryparams.ctime, page: page.current }).then(res => {
      if (res.list === 'bad') {
        return
      }
      let datalist = res.list
      setSearchlist(datalist)
    })
  }

  // 产品SKU
  const getSku = () => {
    getGoodsSku().then(res => {
      if (res.code === 1) {
        setSku(res.msg)
      }
    })
  }

  const onChangeHeat = (value) => {
    setQueeryparams({
      heat: value,
      goodtype: queryparams.goodtype,
      ctime: queryparams.ctime
    })
  }

  const onChangeGoodtype = (value) => {
    setQueeryparams({
      heat: queryparams.heat,
      goodtype: value,
      ctime: queryparams.ctime
    })
  }

  // 时间筛选
  const onClickTime = () => {
    let qtime = moment(startDate).format("YYYY-MM-DD")
    setQueeryparams({
      heat: queryparams.heat,
      goodtype: queryparams.goodtype,
      ctime: qtime
    })
  }

  // 滚动加载
  const fetchMoreData = () => {
    page.current++
    setTimeout(() => {
      base({ self: 1, heat: queryparams.heat, goods_id: queryparams.goodtype, ctime: queryparams.ctime, page: page.current }).then(res => {
        if (res.list === 'bad') {
          return
        }
        let datalist = res.list
        let tempMessagelist = [...searchlist, ...datalist]
        setSearchlist(tempMessagelist)
      })
    }, 100)
  }

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      history.push('/')
    }
  })

  useEffect(() => {
    getSku()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getBase()
  }, [queryparams.heat, queryparams.goodtype, queryparams.ctime, flashNum.current]) // eslint-disable-line react-hooks/exhaustive-deps


  // 点赞
  const throttleFun = throttle(function (id, event) {
    let dom = event.target
    dom = dom.classList.contains('like') ? dom : dom.parentNode

    if (dom.classList.contains('is_praise')) {
      communityCancelPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) - 1
          dom.classList.remove('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.remove('hidedzanimation')
        }
      })
    } else {
      communityPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) + 1
          dom.classList.add('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.add('hidedzanimation')
        }
      })
    }
  }, 1000)

  const putLike = (id, event) => {
    throttleFun(id, event)
  }

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  const onDeleteCommunity = (id, sort) => {
    setArtcid(id)
    setArtcsort(sort)
    handleShow()
  }

  const handleDelete = () => {
    deleteCommunity({ id: artcid }).then(res => {
      if (res.code === 1) {
        message.success(res.msg)
        setTimeout(() => {
          getBase()
        }, 400)
      }
    })
    setModalShow(false)
  }

  return (
    <>
      <div className='container_wrapper search'>
        <Form inline>
          <Form.Label className='my-1 mr-2'>
            Product:
          </Form.Label>
          <Form.Control
            as='select'
            className='my-1 mr-sm-2'
            size='sm'
            custom
            value={queryparams.goodtype}
            onChange={value => onChangeGoodtype(value.target.options[value.target.options.selectedIndex].value)}
          >
            {
              sku.map((sku) => (
                <option value={sku.id} key={sku.id}>{sku.goods_unit}</option>
              ))
            }
            <option value='0'>All</option>
          </Form.Control>
          <Form.Label className='my-1 mr-2 ml-3'>
            Top:
          </Form.Label>
          <Form.Control
            as='select'
            className='my-1 mr-sm-2'
            size='sm'
            custom
            value={queryparams.heat}
            onChange={value => onChangeHeat(value.target.options[value.target.options.selectedIndex].value)}
          >
            <option value='1'>view</option>
            <option value='2'>like</option>
            <option value='3'>comment</option>
            <option value='0'>all</option>
          </Form.Control>
          <Form.Label className='my-1 mr-2 ml-3' htmlFor='date'>
            Time:
          </Form.Label>
          <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
          <Button size='sm' className='screening_btn' onClick={onClickTime}>Filter</Button>
        </Form>
      </div>
      <main className='py-3'>
        <InfiniteScroll
          dataLength={searchlist.length}
          next={fetchMoreData}
          hasMore={true}
        >
          <ListGroup>
            {searchlist.map((search, index) => (
              <ListGroup.Item key={index}>
                <div className='title'>
                  <h5><Nav.Link href={`/detail/${search.id}`}><Badge variant={search.is_check === 1 ? 'success' : search.is_check === 2 ? 'danger' : 'secondary'}>{search.is_check === 1 ? 'Approved' : search.is_check === 2 ? 'Unapproved' : 'Under review'}</Badge> {search.title}</Nav.Link></h5>
                  <span>{moment(search.ctime * 1000).format('YYYY-MM-DD')}</span>
                </div>
                <div className='content'>
                  {search.pic !== '' &&
                    <Nav.Link className='img_link' href={`/detail/${search.id}`}><Image fluid src={search.pic} /></Nav.Link>
                  }
                  <p><Nav.Link className='cont_link' href={`/detail/${search.id}`}><span className='cont' dangerouslySetInnerHTML={{ __html: search.content }}></span><span> Read the full text<i className='iconfont icon-zhankai'></i></span></Nav.Link></p>
                </div>
                <div className='info'>
                  <span
                    className={search.is_praise ? 'like is_praise' : 'like'}
                    onClick={(event) => putLike(search.id, event)}
                  >
                    <i className='iconfont icon-dianzan'></i><b className='praise_num'>{search.praise}</b>
                    <div className='hidedz'>+1</div>
                  </span>
                  <span className='view'><i className='iconfont icon-yanjing-'></i>{search.browset}</span>
                  <span className='talk'><Nav.Link href={`/detail/${search.id}#comment`}><i className='iconfont icon-pinglun'></i>{search.comment}</Nav.Link></span>
                  <span className='delete' onClick={() => onDeleteCommunity(search.id, search.is_sort)}><i className='iconfont icon-delete'></i></span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          {
            searchlist.length <= 0 &&
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </InfiniteScroll>
      </main>

      <Modal show={modalShow} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tips</Modal.Title>
        </Modal.Header>
        <Modal.Body>{artcsort === 1 ? 'It is popular post that can be deleted approved by the admin. Please contact the admin to delete. Are you sure to delete?' : 'Are you sure you want to delete this post?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default User