import React, { useState, createContext, useRef} from 'react'
import { Nav, Navbar, Form, Button } from 'react-bootstrap'
import { BrowserRouter as Router, Route, useRouteMatch, Switch, NavLink } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Message from './Message/Message'

import './Blog.css';
import 'react-datepicker/dist/react-datepicker.css';

export const HeatContext = createContext()

const Blog = () => {
  let { path } = useRouteMatch()
  const [startDate, setStartDate] = useState(new Date())
  const [queryparams, setQueeryparams] = useState({
    heat: 0,
    ctime: 0
  })
  const childRef = useRef()

  const onChangeHeat = (value) => {
    setQueeryparams({
      heat: value,
      ctime: queryparams.ctime
    })
  }

  const onClickTime = () => {
    let qtime = moment(startDate).format("YYYY-MM-DD")
    setQueeryparams({
      heat: queryparams.heat,
      ctime: qtime
    })
    childRef.current.onQueryData(qtime)
  }

  return (
    <>
      <Router>
        <div className='container_wrapper'>
          <Navbar>
            <Nav>
              <Nav.Item>
                <NavLink to='/blog' exact activeClassName='active' className={'nav-link'}>Latest news</NavLink>
              </Nav.Item>
              <Nav.Item className='ml-5'>
                <NavLink to='/blog/activity' activeClassName='active' className={'nav-link'}> Event</NavLink>
              </Nav.Item>
            </Nav>
            <Form inline>
              <Form.Label className='my-1 mr-2'>
                Top:
              </Form.Label>
              <Form.Control
                as='select'
                className='my-1 mr-sm-2'
                size='sm'
                custom
                value={queryparams.heat}
                onChange={value => onChangeHeat(value.target.options[value.target.options.selectedIndex].value)}
              >
                <option value='1'>view</option>
                <option value='2'>like</option>
                <option value='3'>comment</option>
                <option value='0'>all</option>
              </Form.Control>
              <Form.Label className='my-1 mr-2 ml-3' htmlFor='date'>
                Time:
              </Form.Label>
              <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
              <Button size='sm' className='screening_btn' onClick={onClickTime}>Filter</Button>
            </Form>
          </Navbar>
        </div>
        <main className='py-3'>
          <Switch>
            <Route exact path={path}>
              <HeatContext.Provider value={queryparams}><Message ref={childRef} /></HeatContext.Provider>
            </Route>
            <Route exact path={`/blog/activity`}>
              <HeatContext.Provider value={queryparams}><Message ref={childRef} /></HeatContext.Provider>
            </Route>
          </Switch>
        </main>
      </Router>
    </>
  )
}

export default Blog