import React, { useState, createRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Row, Col, Container, Form, Image, Button } from 'react-bootstrap'
import { message } from 'antd'
import googleSign from '@/utils/googleSign'

import './Signin.css';
// import TwitterImg from '@/assets/images/twitter.png';
import FacebookImg from '@/assets/images/facebook.png';
import GoogleImg from '@/assets/images/google.png';

import { sign, facebook, google } from '@/utils/axios'

const Signin = (props) => {
  let history = useHistory()
  const [validated, setValidated] = useState(false)
  const nameRef = createRef()
  const pwdRef = createRef()

  // 账号登录
  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    }

    let params = {
      username: nameRef.current.value,
      password: pwdRef.current.value
    }

    sign(params).then(res => {
      if (res.code === 1) {
        message.success('login successful!')
        localStorage.setItem('token', res.msg)
        let { onHide } = props
        onHide()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        message.error(res.msg)
      }
    })
  }

  // const testAPI = () => {
  //   window.FB.api('/me', response => {
  //     console.log(response.name)
  //   })
  // }

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      facebook({
        accessToken: response.authResponse.accessToken,
        userID: response.authResponse.userID,
        reauthorize_required_in:
          response.authResponse.reauthorize_required_in,
      })
        .then(res => {
          if (res.code === 1) {
            localStorage.setItem('token', res.msg)
            message.success('login successful!')
            let { onHide } = props
            onHide()
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            window.FB.logout()
            message.error(res.msg)
            let { onHide } = props
            onHide()
          }
        })
        .catch(() => {
          message.error('Server error, please try again later!')
        })
    } else if (response.status === 'not_authorized') {
      message.error('Failed to authorize')
    } else {
      message.error('Failed to login')
    }
  }

  const checkLoginState = () => {
    window.FB.getLoginStatus(response => {
      statusChangeCallback(response)
    })
  }

  // facebook 登录
  const onFacebook = () => {
    window.FB.login(checkLoginState())
  }

  /**
  * 谷歌登录成功的回调
  */
  const googleCallbackHandler = (getAuthInstance) => {
    let GoogleUser = getAuthInstance.currentUser.get()
    google({ access_token: GoogleUser.getAuthResponse(true).access_token })
      .then((res) => {
        if (res.code === 1) {
          localStorage.setItem('token', res.msg)
          message.success('login successful!')
          let { onHide } = props
          onHide()
          setTimeout(() => {
            history.push('/')
            window.location.reload()
          }, 1000)
        } else {
          message.error(res.msg)
          let { onHide } = props
          onHide()
        }
      })
      .catch(() => {
        message.error('Server error, please try again later!')
      })
  }

  // google 登录
  const onGoogle = () => {
    const token = localStorage.getItem('token')
    if (token && token !== null) {
      googleCallbackHandler(googleSign.getAuthInstance()) //已经登录的回调
    } else {
      googleSign.googleSignIn(
        () => {
          googleCallbackHandler(googleSign.getAuthInstance())
        },
        () => {
          message.error('Failed to login')
        }
      )
    }
  }

  return (
    <>
      <Modal {...props} centered className='sign_modal'>
        <Modal.Header closeButton>
          <Modal.Title>
            User login
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  size='lg'
                  placeholder='username'
                  className='name'
                  ref={nameRef}
                  type='email'
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size='lg'
                  type="password"
                  placeholder='password'
                  className='pwd'
                  minLength='6'
                  maxLength='20'
                  ref={pwdRef}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Button size='lg' block type="submit">Log in</Button>
              </Form.Group>
              <Form.Group>
                <p className='to_register'>No account? <a href='https://www.ibengoo.com/login/register' target='_blank' rel='noopener noreferrer'>Go to sign up</a></p>
              </Form.Group>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              {/* <Col md={2}>
                <Image fluid roundedCircle src={TwitterImg} />
              </Col> */}
              <Col xs={2}>
                <Image onClick={onFacebook} fluid roundedCircle src={FacebookImg} />
              </Col>
              <Col xs={2}>
                <Image onClick={onGoogle} fluid roundedCircle src={GoogleImg} />
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Signin