import DefaultAvatar from '@/assets/images/avatar2.jpg';

export const userInfo = {
  avatar: DefaultAvatar,
  count: 0,
  cus: '',
  email: '',
  id: 0,
  nickname: '',
  point: 0,
  third: 0,
  birth: '',
  city: '',
  country: '',
  sex: 0,
}

export function reducer(state, action) {
  console.log()
  // switch (action) {
  //   case 'user':
  // return {
  //   ...state,
  //   avatar: state.avatar || DefaultAvatar,
  //   count: state.count,
  //   cus: state.cus,
  //   email: state.email,
  //   id: state.id,
  //   nickname: state.nickname,
  //   point: state.point,
  //   third: state.third
  // }
  // }
  return action
}