import axios from 'axios'

// 创建实例
const request = axios.create({
  baseURL: '/api',
  timeout: 5000
});

// 请求拦截
request.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.authorization = token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// 响应拦截
request.interceptors.response.use(
  response => {
    // 拦截响应，做统一处理
    if (response.headers.token) {
      // store.commit('SET_TOKEN', response.headers.token)
    }
    return response.data
  },
  // 接口错误状态处理
  error => {
    return Promise.reject(error.response)
  }
)

export default request