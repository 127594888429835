import request from '@/utils/request'

const api = {
  base: '/community/getComment',
  detail: '/community/getDetail',
  addComment: '/community/addComment',
  addReply: '/community/addReply',
  getGoodsSku: '/community/getGoodsSku',
  upload: '/goods/upload',
  addCommunity: '/community/addCommunity',
  getUserInfo: '/order/getUserInfo',
  sign: '/login/login',
  facebook: '/login/facebook', // facebook登录
  google: '/login/google', // google登录
  communityPraise: '/community/communityPraise',
  commentPraise: '/community/commentPraise',
  communityCancelPraise: '/community/communityCancelPraise',
  cancelPraise: '/community/cancelPraise',
  browset: '/community/browset',
  getUserCommunity: '/community/getUserCommunity',
  deleteCommunity: '/community/deleteCommunity',
  getCommunityNews: '/community/getCommunityNews',
  newsDetail: '/community/newsDetail',
  newsAllDetele: '/community/newsAllDetele',
  newsDetele: '/community/newsDetele',
  look: '/community/look',
  lookAll: '/community/lookAll',
  getCommunityCommentNews: '/community/getCommunityCommentNews',
  lookCommunity: '/community/lookCommunity',
  lookAllCommunity: '/community/lookAllCommunity',
  commentNewsDetail: '/community/CommentNewsDetail',
  commentNewsAllDetele: '/community/commentNewsAllDetele',
  commentNewsDetele: '/community/commentNewsDetele',
}

// 默认列表
export function base(data) {
  return request.request({
    url: api.base,
    method: 'get',
    params: data
  })
}

// 详情
export function detail(data) {
  return request.request({
    url: api.detail,
    method: 'get',
    params: data
  })
}

// 评论
export function addComment(data) {
  return request.request({
    url: api.addComment,
    method: 'post',
    data
  })
}

// 回复
export function addReply(data) {
  return request.request({
    url: api.addReply,
    method: 'post',
    data
  })
}

// 产品类型
export function getGoodsSku(data) {
  return request.request({
    url: api.getGoodsSku,
    method: 'get',
    params: data
  })
}

// 图片上传
export function upload(data) {
  return request.request({
    url: api.upload,
    method: 'post',
    data
  })
}

// 发帖
export function addCommunity(data) {
  return request.request({
    url: api.addCommunity,
    method: 'post',
    data
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return request.request({
    url: api.getUserInfo,
    method: 'get',
    params: data
  })
}

// 登录
export function sign(data) {
  return request({
    url: api.sign,
    method: 'post',
    data
  })
}

// facebook登录
export function facebook(params) {
  return request({
    url: api.facebook,
    method: 'post',
    data: params
  })
}

// google登录
export function google(params) {
  return request({
    url: api.google,
    method: 'post',
    data: params
  })
}

// 帖子点赞
export function communityPraise(data) {
  return request({
    url: api.communityPraise,
    method: 'post',
    data
  })
}

// 回复点赞
export function commentPraise(data) {
  return request({
    url: api.commentPraise,
    method: 'post',
    data
  })
}

// 帖子取消点赞
export function communityCancelPraise(data) {
  return request({
    url: api.communityCancelPraise,
    method: 'post',
    data
  })
}

// 回复取消点赞
export function cancelPraise(data) {
  return request({
    url: api.cancelPraise,
    method: 'post',
    data
  })
}

// 浏览量
export function browset(data) {
  return request({
    url: api.browset,
    method: 'post',
    data
  })
}

// 用户发帖信息
export function getUserCommunity(data) {
  return request({
    url: api.getUserCommunity,
    method: 'get',
    params: data
  })
}

// 删帖
export function deleteCommunity(data) {
  return request({
    url: api.deleteCommunity,
    method: 'post',
    data
  })
}

// 站内信
export function getCommunityNews(data) {
  return request({
    url: api.getCommunityNews,
    method: 'get',
    params: data
  })
}

// 站内信详情
export function newsDetail(data) {
  return request({
    url: api.newsDetail,
    method: 'get',
    params: data
  })
}

// 删除已读站内信
export function newsAllDetele(data) {
  return request({
    url: api.newsAllDetele,
    method: 'post',
    data
  })
}

// 删除单个站内信
export function newsDetele(data) {
  return request({
    url: api.newsDetele,
    method: 'post',
    data
  })
}

// 标记全部为已读站内信
export function lookAll(data) {
  return request({
    url: api.lookAll,
    method: 'post',
    data
  })
}

// 标记单个为已读站内信
export function look(data) {
  return request({
    url: api.look,
    method: 'post',
    data
  })
}

// 评论消息
export function getCommunityCommentNews(data) {
  return request({
    url: api.getCommunityCommentNews,
    method: 'get',
    params: data
  })
}

// 标记全部为已读评论消息
export function lookAllCommunity(data) {
  return request({
    url: api.lookAllCommunity,
    method: 'post',
    data
  })
}

// 标记单个为已读评论消息
export function lookCommunity(data) {
  return request({
    url: api.lookCommunity,
    method: 'post',
    data
  })
}

// 评论消息详情
export function commentNewsDetail(data) {
  return request({
    url: api.commentNewsDetail,
    method: 'get',
    params: data
  })
}

// 删除已读评论消息
export function commentNewsAllDetele(data) {
  return request({
    url: api.commentNewsAllDetele,
    method: 'post',
    data
  })
}

// 删除单个评论消息
export function commentNewsDetele(data) {
  return request({
    url: api.commentNewsDetele,
    method: 'post',
    data
  })
}