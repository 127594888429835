import React, { useState, useEffect, useRef, useContext, useImperativeHandle } from 'react'
import { ListGroup, Image, Nav, Badge } from 'react-bootstrap'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useRouteMatch, useLocation } from "react-router-dom"
import { Empty } from 'antd'
import throttle from 'lodash/throttle'
import Signin from '@/layouts/Signin/Signin'
import { HeatContext } from '../Forum'

import './Ideas.css';

import { base, communityPraise, communityCancelPraise } from '@/utils/axios'

const Ideas = React.forwardRef((props, ref) => {
  let token = localStorage.getItem('token')
  let location = useLocation()
  let pathname = location.pathname
  const match = useRouteMatch('/forum/discuss')
  const category = match ? 2 : 1
  const [modalShow, setModalShow] = useState(false)
  let page = useRef(1)
  let [ideaslist, setIdeaslist] = useState([])
  let { heat, goodtype, ctime } = useContext(HeatContext)

  // 初始化
  const getBase = () => {
    page.current = 1
    base({ type: 1, category, heat, goods_id: goodtype, ctime, page: page.current }).then(res => {
      if (res.list === 'bad') {
        return
      }
      let datalist = res.list
      setIdeaslist(datalist)
    })
  }

  // 滚动加载
  const fetchMoreData = () => {
    page.current++
    setTimeout(() => {
      base({ type: 2, category, heat, goods_id: goodtype, ctime, page: page.current }).then(res => {
        if (res.list === 'bad') {
          return
        }
        let datalist = res.list
        let tempMessagelist = [...ideaslist, ...datalist]
        setIdeaslist(tempMessagelist)
      })
    }, 100)
  }

  useEffect(() => {
    getBase()
  }, [heat, goodtype, pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  // 筛选
  useImperativeHandle(ref, () => ({
    onQueryData: (qtime) => {
      ctime = qtime
      getBase()
    }
  }))

  // 点赞
  const throttleFun = throttle(function (id, event) {
    if (!token || token === null) {
      setModalShow(true)
      return
    }
    let dom = event.target
    dom = dom.classList.contains('like') ? dom : dom.parentNode

    if (dom.classList.contains('is_praise')) {
      communityCancelPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) - 1
          dom.classList.remove('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.remove('hidedzanimation')
        }
      })
    } else {
      communityPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) + 1
          dom.classList.add('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.add('hidedzanimation')
        }
      })
    }
  }, 1000)

  const putLike = (id, event) => {
    throttleFun(id, event)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={ideaslist.length}
        next={fetchMoreData}
        hasMore={true}
      >
        <ListGroup>
          {ideaslist.map((ideas, index) => (
            <ListGroup.Item key={index}>
              <div className='title'>
                <h5><Nav.Link href={`/detail/${ideas.id}`}>{ideas.is_sort === 1 &&
                  <Badge variant={index === 0 ? 'danger' : index === 1 ? 'warning' : 'info'}>TOP{index + 1}</Badge>
                } {ideas.title}</Nav.Link></h5>
                <span>{moment(ideas.ctime * 1000).format('YYYY-MM-DD')}</span>
              </div>
              <div className='content'>
                {ideas.pic !== '' &&
                  <Nav.Link className='img_link' href={`/detail/${ideas.id}`}><div className='img'><Image fluid src={ideas.pic} /></div></Nav.Link>
                }
                <p><Nav.Link className='cont_link' href={`/detail/${ideas.id}`}><span className='cont' dangerouslySetInnerHTML={{ __html: ideas.content }}></span><span> Read the full text<i className='iconfont icon-zhankai'></i></span></Nav.Link></p>
              </div>
              <div className='info'>
                <span
                  className={ideas.is_praise ? 'like is_praise' : 'like'}
                  onClick={(event) => putLike(ideas.id, event)}
                >
                  <i className='iconfont icon-dianzan'></i><b className='praise_num'>{ideas.praise}</b>
                  <div className='hidedz'>+1</div>
                </span>
                <span className='view'><i className='iconfont icon-yanjing-'></i>{ideas.browset}</span>
                <span className='talk'><Nav.Link href={`/detail/${ideas.id}#comment`}><i className='iconfont icon-pinglun'></i>{ideas.comment}</Nav.Link></span>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        {
          ideaslist.length <= 0 &&
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </InfiniteScroll>
      <Signin show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
})

export default Ideas