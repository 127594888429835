import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button, ListGroup, Image, Nav, Modal } from 'react-bootstrap'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Empty, message } from 'antd'
import { useHistory, NavLink } from "react-router-dom"
import { Context } from '@/App'

import './Msg.css';

import { getCommunityCommentNews, lookCommunity, lookAllCommunity, commentNewsDetele, commentNewsAllDetele, getUserInfo } from '@/utils/axios'

const Msg = () => {
  const { dispatch } = useContext(Context)
  let history = useHistory()
  let page = useRef(1)
  const [modalShow, setModalShow] = useState(false)
  let [msglist, setMsglist] = useState([])
  let [islook, setIslook] = useState(1)
  let [id, setId] = useState(0)

  const onGetUserInfo = () => {
    getUserInfo().then(res => {
      if (res.code === 1) {
        dispatch(res.msg)
      } else if (res.code === 1000) {
        message.error(res.msg)
        localStorage.removeItem('token')
      } else {
        localStorage.removeItem('token')
      }
    })
  }

  // 获取评论消息
  const getBase = () => {
    page.current = 1
    getCommunityCommentNews({ page: page.current }).then(res => {
      if (res.list === 'bad') {
        return
      }
      let datalist = res.list
      setMsglist(datalist)
    })
  }

  // 滚动加载
  const fetchMoreData = () => {
    page.current++
    setTimeout(() => {
      getCommunityCommentNews({ page: page.current }).then(res => {
        if (res.list === 'bad') {
          return
        }
        let datalist = res.list
        let tempMessagelist = [...msglist, ...datalist]
        setMsglist(tempMessagelist)
      })
    }, 100)
  }

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      history.push('/')
    }
  })

  useEffect(() => {
    getBase()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // 单个标记已读
  const onLook = (id) => {
    lookCommunity({ id }).then(res => {
      if (res.code === 1) {
        onGetUserInfo()
        getBase()
      }
    })
  }
  // 一键已读
  const onAllLook = () => {
    lookAllCommunity().then(res => {
      if (res.code === 1) {
        onGetUserInfo()
        getBase()
      }
    })
  }

  const handleShow = (id, look) => {
    id !== null ? setId(id) : setId(0)
    if (look !== undefined) { setIslook(look) }
    setModalShow(true)
  };
  const handleClose = () => setModalShow(false);

  // 删除单个或全部已读
  const onDelete = () => {
    if (id !== 0) {
      commentNewsDetele({ id }).then(res => {
        console.log(res)
        if (res.code === 1) {
          message.success(res.msg)
          getBase()
        }
      })
    } else {
      commentNewsAllDetele().then(res => {
        if (res.code === 1) {
          message.success(res.msg)
          getBase()
        }
      })
    }
  }

  const handleDelete = () => {
    onDelete()
    setModalShow(false)
  }

  return (
    <>
      <div className='container_wrapper msg msg-start'>
        <NavLink activeClassName='active' to='/msg/comm'>Comments & Replies</NavLink>
        <NavLink activeClassName='active' to='/msg/system'>Notifications</NavLink>
      </div>
      <div className='container_wrapper msg'>
        <div className='btn all_look_btn' onClick={onAllLook}><i className='iconfont icon-biaoji'></i> Read all</div>
        <div className='btn all_delete_btn' onClick={() => handleShow(null)}><i className='iconfont icon-yijianshanchu'></i> Delete read</div>
      </div>
      <main className='msgpage py-3'>
        <InfiniteScroll
          dataLength={msglist.length}
          next={fetchMoreData}
          hasMore={true}
        >
          <ListGroup>
            {msglist.map((msg, index) => (
              <ListGroup.Item key={index}>
                <div className='content'>
                  <Nav.Link className='img_link' href={`/msginfod/${msg.id}`}><Image fluid roundedCircle rounded src={msg.avatar} /></Nav.Link>
                  <div className='content_text'>
                    <div className='title'>
                      <h6><Nav.Link href={`/msginfod/${msg.id}`}>{msg.cnick || msg.cname} {msg.title}<b> {msg.community_title}</b></Nav.Link></h6>
                      <span>{moment(msg.ctime * 1000).format('YYYY-MM-DD')}</span>
                    </div>
                    <p className='comm_content'><Nav.Link className='cont_link' href={`/msginfod/${msg.id}`}><span className='cont' dangerouslySetInnerHTML={{ __html: msg.content }}></span></Nav.Link></p>
                  </div>
                </div>
                <div className='info'>
                  {
                    msg.is_look === 0 &&
                    <span className='biaoji' onClick={() => onLook(msg.id)}><i className='iconfont icon-biaoji'></i></span>
                  }
                  <span className='delete' onClick={() => handleShow(msg.id, msg.is_look)}><i className='iconfont icon-delete'></i></span>
                </div>
                {
                  msg.is_look === 0 &&
                  <span className='is_look'><i className='iconfont icon-dian'></i></span>
                }
              </ListGroup.Item>
            ))}
          </ListGroup>
          {
            msglist.length <= 0 &&
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </InfiniteScroll>
      </main>

      <Modal show={modalShow} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tips</Modal.Title>
        </Modal.Header>
        <Modal.Body>{islook === 0 ? 'The message has not been read, are you sure to delete it?' : 'Are you sure you want to delete this message?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Msg