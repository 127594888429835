import React, { useState, useEffect, useContext } from 'react'
import { Button, Image, Nav, Jumbotron, Modal } from 'react-bootstrap'
import moment from 'moment'
import { message } from 'antd'
import { useHistory, useParams } from "react-router-dom"
import { Context } from '@/App'

import './MsgDetail.css';

import { newsDetail, newsDetele, look, getUserInfo } from '@/utils/axios'

const MsgDetailSystem = () => {
  let { id } = useParams()
  const { dispatch } = useContext(Context)
  let history = useHistory()
  const [modalShow, setModalShow] = useState(false)
  let [msgobj, setMsgobj] = useState({})

  const onGetUserInfo = () => {
    getUserInfo().then(res => {
      if (res.code === 1) {
        dispatch(res.msg)
      } else if (res.code === 1000) {
        message.error(res.msg)
        localStorage.removeItem('token')
      } else {
        localStorage.removeItem('token')
      }
    })
  }

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      history.push('/')
    }

    look({ id }).then(res => {
      if (res.code === 1) {
        onGetUserInfo()
      }
    })
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    newsDetail({ id }).then(res => {
      if (res.code === 1) {
        setMsgobj(res.msg)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const handleShow = () => {
    setModalShow(true)
  };
  const handleClose = () => setModalShow(false);

  // 删除
  const onDelete = () => {
    newsDetele({ id }).then(res => {
      console.log(res)
      if (res.code === 1) {
        message.success(res.msg)
        setTimeout(() => {
          history.push('/msg')
        }, 400)
      }
    })
  }

  const handleDelete = () => {
    onDelete()
    setModalShow(false)
  }

  return (
    <>
      <main className='msginfo py-3'>
        <Jumbotron>
          <h4><Image fluid roundedCircle rounded src={msgobj.avatar} />{msgobj.title}<Nav.Link href={`/detail/${msgobj.cid}`}>{msgobj.community_title}</Nav.Link></h4>
          <h6 className='date'>{moment(msgobj.ctime * 1000).format('YYYY-MM-DD')}</h6>
          <p className='remake'>{msgobj.content}</p>
          <p className='see'><Nav.Link href={`/detail/${msgobj.cid}`}>View the post</Nav.Link></p>
          <div className='info'>
            <span className='delete' onClick={handleShow}><i className='iconfont icon-delete'></i></span>
          </div>
        </Jumbotron>
      </main>

      <Modal show={modalShow} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tips</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this message?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MsgDetailSystem