import React, { useState, useEffect, useContext } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import { Picker } from 'emoji-mart'
import { message } from 'antd'
import { ReplyFormContext } from '@/components/Detail/Detail'

import 'emoji-mart/css/emoji-mart.css';
import './Reply.css';

import { addReply } from '@/utils/axios'


const Reply = (props) => {
  const [replyemojishow, setReplyemojishow] = useState(false)
  const [replycontent, setReplycontent] = useState('')

  const replyparmas = useContext(ReplyFormContext)

  useEffect(() => {
    document.addEventListener('click', (e) => {
      let parDom = document.querySelector('.reply_form .emoji-mart')
      let emojiBtnDom = document.querySelector('.reply_form .emoji_btns span')
      if (parDom) {
        parDom.contains(e.target) || emojiBtnDom.contains(e.target) ? setReplyemojishow(true) : setReplyemojishow(false)
      }
    })
  }, [])

  // 显示emoji
  const showEmoji = () => {
    setReplyemojishow(true)
  }

  // 选择表情包
  const addEmoji = (emoji) => {
    setReplycontent(replycontent + emoji.native)
  }

  // 输入框
  const onChatContentChange = (e) => {
    setReplycontent(e.target.value)
  }
  // 回复
  const onReply = () => {
    if (!replycontent) {
      message.warning('Comment content cannot be empty!')
      return
    }
    const params = {
      cid: replyparmas.cid,
      id: replyparmas.id,
      content: replycontent
    }
    addReply(params).then(res => {
      if (res.code === 1) {
        message.success(res.msg)
        setTimeout(() => {
          setReplycontent('')
          props.HideReply()
          props.GetDetail()
        }, 400)
      } else {
        message.error(res.msg)
      }
    })
  }

  return (
    <>
      <div className='reply_form'>
        <Form>
          <Form.Group as={Row} className='form_row'>
            <Col xs={12} md={12} lg={12} >
              <TextareaAutosize placeholder={`Reply ${replyparmas.name}:`} onChange={onChatContentChange} value={replycontent} />
            </Col>
            <Col xs={9} sm={11} >
              <div className='emoji_btns'>
                <span onClick={showEmoji}><i className={replyemojishow ? 'iconfont icon-biaoqing active' : 'iconfont icon-biaoqing'}></i></span>
              </div>
            </Col>
            <Col xs={3} sm={1} className='comment_btn_box'>
              <Button className='comment_btn' data-dismiss="alert" onClick={onReply}>Submit</Button>
            </Col>
          </Form.Group>
        </Form>
        {
          replyemojishow &&
          <Picker set='apple' onSelect={addEmoji} />
        }
      </div>
    </>
  )
}

export default Reply
