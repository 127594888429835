import React, { useState, useEffect, useContext } from 'react'
import { useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom'
import { Navbar, Nav, Container, Form, FormControl, Image, Button, Badge } from 'react-bootstrap'
import { message, Popover } from 'antd'
import Signin from '@/layouts/Signin/Signin'
import googleSign from '@/utils/googleSign'

import { Context } from '@/App'

import './Head.css';

import DefaultAvatar from '@/assets/images/avatar2.jpg';
import LogoImg from '@/assets/images/logo.png';

import { getUserInfo, facebook, google } from '@/utils/axios'

const Header = () => {
  const { state, dispatch } = useContext(Context)
  let history = useHistory()
  const matchblog = useRouteMatch("/blog")
  const matchforum = useRouteMatch("/forum")
  const [keywords, setKeywords] = useState('')
  let params = useLocation()
  const [modalShow, setModalShow] = useState(false)

  const checkFacebookLogin = () => {
    window.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        facebook({
          accessToken: response.authResponse.accessToken,
          userID: response.authResponse.userID,
          reauthorize_required_in:
            response.authResponse.reauthorize_required_in
        }).then(res => {
          if (res.code === 1) {
            // window.FB.api('/me', resp => {
            //   // setUsename(resp.name)
            //   // dispatch(resp.name)
            // })
            localStorage.setItem('token', res.msg)
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }
        })
      }
    })
  }

  const googleCallbackHandler = (getAuthInstance) => {
    let GoogleUser = getAuthInstance.currentUser.get()
    google({ access_token: GoogleUser.getAuthResponse(true).access_token })
      .then((res) => {
        if (res.code === 1) {
          localStorage.setItem('token', res.msg)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
  }

  // google 登录
  const checkGoogleLogin = () => {
    if (window.getAuthInstance?.isSignedIn.get()) {
      googleSign.googleSignIn(
        () => {
          googleCallbackHandler(googleSign.getAuthInstance())
        }
      )
    }
  }

  useEffect(() => {
    let queryToken = params.search.substr(5)
    let token = localStorage.getItem('token')
    if (queryToken) {
      localStorage.setItem('token', queryToken)
    } else {
      if (token && token !== null) {
        localStorage.setItem('token', token)
      } else {
        setTimeout(() => {
          checkFacebookLogin()
          checkGoogleLogin()
        }, 400)
      }
    }

    getUserInfo().then(res => {
      if (res.code === 1) {
        dispatch(res.msg)
      } else if (res.code === 1000) {
        message.error(res.msg)
        localStorage.removeItem('token')
        history.push('/')
      } else {
        localStorage.removeItem('token')
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchWords = () => {
    if (!keywords.trim()) {
      message.warning('Please enter the keywords to search!')
      return
    }
    history.push(`/search/${keywords}`)
  }

  const onKeyDownchange = (e) => {
    if (e.keyCode === 13) {
      onSearchWords()
    }
  }

  // 退出登录
  const layout = () => {
    let isFBLogin = window.FB.getLoginStatus(res => {
      return res.status
    })
    // facebook退出
    if (window.FB && isFBLogin === 'connected') {
      window.FB.logout()
    }
    // google 退出
    if (window.getAuthInstance && window.getAuthInstance.isSignedIn.get()) {
      googleSign.googleSignOut(
        () => { },
        () => {
          message.error('Failed to exit!')
        }
      )
    }
    localStorage.removeItem('token')
    setTimeout(() => {
      history.push('/')
      window.location.reload()
    }, 500)
  }

  return (
    <>
      <Navbar className='nav_bar' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <a href='/' target="_blank" rel='noreferrer'>
            <Navbar.Brand><Image src={LogoImg} /></Navbar.Brand>
          </a>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <span className='blank_tag'></span>
            <Nav className='mr-auto'>
              <Link to='/blog' className={matchblog ? 'nav-link active' : 'nav-link'}>Blog</Link>
              <Link to='/forum' className={matchforum ? 'nav-link active' : 'nav-link'}> Forum</Link>
            </Nav>
            <Form inline>
              <FormControl
                type='text'
                placeholder='Search'
                className='mr-sm-2'
                value={keywords}
                onChange={(value) => setKeywords(value.target.value)}
                onKeyDown={e => onKeyDownchange(e)}
              />
              <Button className='search_btn' onClick={onSearchWords}>Search</Button>
            </Form>
            {
              state.nickname || state.email ?
                <Popover className='userinfo' placement="bottom" title={''} content={
                  <div className='user_down_menu'>
                    <p><Link to='/user'>My posts</Link></p>
                    <p><Link to='/msg'>My message {state.count > 0 && <Badge variant="danger">{state.count}</Badge>}</Link></p>
                    <p><a onClick={e => {
                      e.preventDefault()
                      layout()
                    }} href="" rel='noreferrer'>Log out</a></p>
                  </div>
                }>
                  <span className='avatar'><Image src={state.avatar ? state.avatar : DefaultAvatar} /></span>
                  <span className='username'>
                    {state.nickname || state.email}
                  </span>
                </Popover>
                :
                <span className='signinBtn' onClick={() => setModalShow(true)}>
                  {'Login'}
                </span>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Signin show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Header
