import React, { useState, useEffect, useRef, createContext } from 'react'
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { Container, Image, ListGroup, Form, Row, Col, Button } from 'react-bootstrap'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import { message } from 'antd'
import throttle from 'lodash/throttle'
import TextareaAutosize from 'react-textarea-autosize'
import { Picker } from 'emoji-mart'
import InfiniteScroll from 'react-infinite-scroll-component'
import Reply from '@/components/Reply/Reply'
import Signin from '@/layouts/Signin/Signin'

import './Detail.css';

import TwitterImg from '@/assets/images/twitter.png';
import FacebookImg from '@/assets/images/facebook.png';
import LinkImg from '@/assets/images/link.png';
import DefaultAvatar from '@/assets/images/avatar2.jpg';
import VipImg from '@/assets/images/vip.png';

import { detail, addComment, communityPraise, commentPraise, browset, communityCancelPraise, cancelPraise, getUserCommunity } from '@/utils/axios'

export const ReplyFormContext = createContext()

const Detail = () => {
  let location = useLocation()
  let history = useHistory()
  let { id } = useParams()
  let token = localStorage.getItem('token')
  const [urllink] = useState(window.location.href)
  const [communityobj, setCommunityobj] = useState({})
  const [total, setTotal] = useState(0)
  const [commentarr, setCommentarr] = useState([])
  let page = useRef(1)
  const [emojishow, setEmojishow] = useState(false)
  const [commentcontent, setCommentcontent] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [authInfo, setAuthInfo] = useState({
    avatar: DefaultAvatar,
    nickname: '',
    community: 0,
    praise: 0,
    browset: 0,
    vip: 0
  })

  let currentid = useRef(0)
  let currentboolean = useRef(false)

  // 获取详情
  const getDetail = () => {
    page.current = 1
    detail({ id, page: page.current }).then(res => {
      if (res.code === 1) {
        setCommunityobj(res.community)
        setTotal(res.total)
        let datalist = res.list
        setCommentarr(datalist)
      } else {
        message.warning('The post does not exist or has been deleted')
        setTimeout(() => {
          history.push('/')
        }, 1000)
      }
    })
  }

  const onBrowset = () => {
    browset({ id }).then(() => { })
  }

  // 作者
  const onGetUserCommunity = () => {
    getUserCommunity({ id }).then(res => {
      if (res.code === 1) {
        setAuthInfo({
          avatar: res.msg.avatar ? res.msg.avatar : DefaultAvatar,
          nickname: res.msg.nickname,
          community: res.msg.community,
          praise: res.msg.praise,
          browset: res.msg.browset,
          vip: res.msg.vip ? res.msg.vip : 0
        })
      }
    })
  }

  useEffect(() => {
    getDetail()
    onBrowset()
    onGetUserCommunity()
    document.addEventListener('click', (e) => {
      let parDom = document.querySelector('.comment_form .emoji-mart')
      let emojiBtnDom = document.querySelector('.comment_form .emoji_btns span')
      if (parDom) {
        parDom.contains(e.target) || emojiBtnDom.contains(e.target) ? setEmojishow(true) : setEmojishow(false)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // 锚点
  const scrollToAnchor = () => {
    let anchorName = location.hash
    if (anchorName) {
      anchorName = anchorName.replace('#', '')
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) { anchorElement.scrollIntoView() }
    }
  }
  scrollToAnchor()
  // 显示emoji
  const showEmoji = () => {
    setEmojishow(true)
  }

  // 选择表情包
  const addEmoji = (emoji) => {
    setCommentcontent(commentcontent + emoji.native)
  }

  // 输入框
  const onChatContentChange = (e) => {
    setCommentcontent(e.target.value)
  }

  // 评价
  const onComment = () => {
    if (!token) {
      setModalShow(true)
      return
    }
    if (!commentcontent) {
      message.warning('Comment content cannot be empty!')
      return
    }
    const params = {
      cid: id,
      content: commentcontent
    }
    addComment(params).then(res => {
      if (res.code === 1) {
        message.success(res.msg)
        page.current = 1
        setTimeout(() => {
          setCommentcontent('')
          setCommentarr([])
          getDetail()
        }, 1000)
      } else {
        message.error(res.msg)
      }
    })
  }

  // 隐藏Reply表单
  const hideReplyForm = (aid) => {
    unmountComponentAtNode(document.getElementById(aid))
  }

  // Reply显示表单
  const showReplyForm = (cid, id, name, nick) => {
    if (!token) {
      setModalShow(true)
      return
    }
    let aid = `${cid}${id}`
    let aname = nick
    if (!nick) {
      aname = name
    }
    let pros = {
      cid,
      id,
      name: aname,
    }
    let wrap_box = document.querySelectorAll('.create_reply_wrap')
    wrap_box.forEach((e) => {
      unmountComponentAtNode(e)
    })
    if (currentid.current !== aid) {
      currentboolean = true
      ReactDOM.render(
        <ReplyFormContext.Provider value={pros}><Reply name={`reply${aid}`} GetDetail={getDetail} HideReply={() => hideReplyForm(aid)} /></ReplyFormContext.Provider>,
        document.getElementById(aid)
      )
    } else {
      currentboolean = !currentboolean
      if (currentboolean) {
        ReactDOM.render(
          <ReplyFormContext.Provider value={pros}><Reply name={`reply${aid}`} GetDetail={getDetail} /></ReplyFormContext.Provider>,
          document.getElementById(aid)
        )
      } else {
        unmountComponentAtNode(document.getElementById(aid))
      }
    }
    currentid.current = aid
  }

  // 滚动加载
  function fetchMoreReplyData() {
    page.current++
    setTimeout(() => {
      detail({ id, page: page.current }).then(res => {
        if (res.code === 1) {
          // if (res.list === 'bad') {
          //   return
          // }
          let datalist = res.list
          let tempMessagelist = [...commentarr, ...datalist]
          setCommentarr(tempMessagelist)
        }
      })
    }, 100)
  }

  // 点赞
  const throttleFun = throttle(function (id, event) {
    if (!token || token === null) {
      setModalShow(true)
      return
    }
    let dom = event.target
    dom = dom.classList.contains('like') ? dom : dom.parentNode

    if (dom.classList.contains('is_praise')) {
      communityCancelPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) - 1
          dom.classList.remove('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.remove('hidedzanimation')
        }
      })
    } else {
      communityPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) + 1
          dom.classList.add('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.add('hidedzanimation')
        }
      })
    }
  }, 1000)

  const putLike = (id, event) => {
    throttleFun(id, event)
  }

  // Reply点赞
  const putCommentLikeFun = throttle(function (id, event) {
    if (!token) {
      setModalShow(true)
      return
    }

    let dom = event.target
    dom = dom.classList.contains('like') ? dom : dom.parentNode

    if (dom.classList.contains('is_praise')) {
      cancelPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) - 1
          dom.classList.remove('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.remove('hidedzanimation')
          getDetail()
        }
      })
    } else {
      commentPraise({ id }).then(res => {
        if (res.code === 1) {
          dom.querySelector('.praise_num').innerHTML = Number(dom.querySelector('.praise_num').innerHTML) + 1
          dom.classList.add('is_praise')
          let dzDom = dom.querySelector('.hidedz')
          dzDom.classList.add('hidedzanimation')
          getDetail()
        }
      })
    }

  })

  const putCommentLike = (id, event) => {
    putCommentLikeFun(id, event)
  }

  // twitter 分享
  const twitterShare = (w, h, left, top) => {
    window.open(`http://twitter.com/intent/tweet?text=${communityobj.title}&url=https://community.ibengoo.com/api/messenger/share?id=${communityobj.id}`,
      'share',
      `left=${left},
      top=${top},
      width=${w},
      height=${h},
      personalbar=0,
      toolbar=0,
      scrollbars=0,
      resizable=0`
    )
  }

  // facebook 分享
  const facebookShare = (w, h, left, top) => {
    window.open(`http://www.facebook.com/sharer.php?t=${communityobj.title}&u=https://admin.ibengoo.com/admin/login/share?id=${communityobj.id}`,
      'sharer',
      `toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      copyhistory=no,
      left=${left},
      top=${top},
      width=${w},
      height=${h}`
    )
  }

  // google 分享
  const googleShare = (w, h, left, top) => {
    window.open(`https://plus.google.com/share?title=${communityobj.title}&url=https://community.ibengoo.com/api/messenger/share?id=${communityobj.id}`,
      'share',
      `toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      copyhistory=no,
      left=${left},
      top=${top},
      width=${w},
      height=${h}`)
  }

  const onShare = (type) => {
    let wLeft = window.screenLeft ? window.screenLeft : window.screenX
    let wTop = window.screenTop ? window.screenTop : window.screenY
    let w = 550
    let h = 450
    let left = wLeft + (window.innerWidth / 2) - (w / 2)
    let top = wTop + (window.innerHeight / 2) - (h / 2)

    switch (type) {
      case 'twitter':
        twitterShare(w, h, left, top)
        break;
      case 'facebook':
        facebookShare(w, h, left, top)
        break;
      case 'google':
        googleShare(w, h, left, top)
        break;
      default:
        break;
    }
  }

  // 复制链接
  const onCopyLink = (e) => {
    let currentDom = e.target
    let ipt = currentDom.previousElementSibling
    ipt.select()
    document.execCommand("Copy")
    message.success('Copy successfully')
  }

  return (
    <>
      <Row>
        <Col md={12} lg={9}>
          <div className='detail'>
            <Container>
              <h5 className='title'>{communityobj.title}</h5>
              <div className='info'>
                <p>Author：{communityobj.nickname || communityobj.name}</p>
                <p>{moment(communityobj.ctime * 1000).format('YYYY-MM-DD')}</p>
              </div>
              {communityobj.pic !== '' &&
                <div className='img'><Image fluid src={communityobj.pic} /></div>
              }
              <div className='content' dangerouslySetInnerHTML={{ __html: communityobj.content }}></div>
              <a id='comment' href='#comment'></a>
              <div className='dz'>
                <div className='detail_like'>
                  <span
                    className={communityobj.is_praise ? 'like is_praise' : 'like'}
                    onClick={(event) => putLike(communityobj.id, event)}
                  >
                    <i className='iconfont icon-dianzan'></i><b className='praise_num'>{communityobj.praise}</b>
                    <div className='hidedz'>+1</div>
                  </span>
                  <span className='view'><i className='iconfont icon-yanjing-'></i>{communityobj.browset + 1}</span>
                  <span className='talk'><i className='iconfont icon-pinglun'></i>{communityobj.comment}</span>
                </div>
                <div className='detail_link'>
                  <span onClick={() => onShare('twitter')} className='other_link'><Image src={TwitterImg} /></span>
                  <span onClick={() => onShare('facebook')} className='other_link'><Image src={FacebookImg} /></span>
                  <span className='other_link'>
                    <input className='url_link' value={urllink} readonly />
                    <Image onClick={onCopyLink} src={LinkImg} />
                  </span>
                </div>
              </div>
              <div className='comment_form'>
                <Form>
                  <Form.Group as={Row} className='form_row'>
                    <Col xs={12} md={12} lg={12} >
                      <TextareaAutosize placeholder='Please enter your comment' onChange={onChatContentChange} value={commentcontent} />
                    </Col>
                    <Col xs={9} sm={10} xl={10} >
                      <div className='emoji_btns'>
                        <span onClick={showEmoji}><i className={emojishow ? 'iconfont icon-biaoqing active' : 'iconfont icon-biaoqing'}></i></span>
                      </div>
                    </Col>
                    <Col xs={3} sm={2} xl={2} className='comment_btn_box'>
                      <Button className='comment_btn' data-dismiss="alert" onClick={onComment}>Submit</Button>
                    </Col>
                  </Form.Group>
                </Form>
                {
                  emojishow &&
                  <Picker set='apple' onSelect={addEmoji} />
                }
              </div>

              <div className='comments_list'>
                <div className='title'>comment list({total})</div>
                <InfiniteScroll
                  dataLength={commentarr.length}
                  next={fetchMoreReplyData}
                  useWindow={true}
                  hasMore={true}
                >
                  <ListGroup>
                    {
                      commentarr.map((comment, index) => (
                        <ListGroup.Item key={index}>
                          <div className='comment_top'>
                            <p>
                              <span className='img_relative'>
                                <Image src={comment.avatar || DefaultAvatar} roundedCircle fluid />
                                {
                                  comment.community === 1 &&
                                  <Image className='vip' src={VipImg} />
                                }
                              </span>
                              {comment.nick || comment.name}</p>
                            <p>{moment(comment.ctime * 1000).format('YYYY-MM-DD')}</p>
                          </div>
                          <div className='comment_content'>{comment.remark}</div>
                          {
                            comment.total > 0 &&
                            <div className='reply_list'>{comment.total} {comment.total >= 2 ? 'Replies' : 'Reply'} <i className='iconfont icon-zhankai'></i></div>
                          }
                          <div className='comment_bottom'>
                            <span
                              className={comment.is_praise ? 'like is_praise' : 'like'}
                              onClick={(event) => putCommentLike(comment.id, event)}
                            >
                              <i className='iconfont icon-dianzan'></i><b className='praise_num'>{comment.praise}</b>
                              <div className='hidedz'>+1</div>
                            </span>
                            <span className='reply_btn' onClick={() => showReplyForm(comment.cid, comment.id, comment.name, comment.nick)}>Reply</span>
                          </div>
                          <div id={`${comment.cid}${comment.id}`} className='create_reply_wrap'></div>
                          <Row>
                            {
                              comment.son && comment.son.map((reply, idx) => (
                                <Col className='reply_item' md={{ span: 11, offset: 1 }} key={idx}>
                                  <div className='replay_name'>
                                    <span className='img_relative'>
                                      <Image src={reply.avatar || DefaultAvatar} roundedCircle fluid />
                                      {
                                        reply.community === 1 &&
                                        <Image className='vip' src={VipImg} />
                                      }
                                    </span>
                                    {reply.nick || reply.name}</div>
                                  <div className='reply_cont'>Reply <span>{reply.cnick || reply.cname}</span>: {reply.remark}</div>
                                  <div className='reply_cz'>
                                    <p>{moment(reply.ctime * 1000).fromNow()}</p>
                                    <div>
                                      <span
                                        className={reply.is_praise ? 'like is_praise' : 'like'}
                                        onClick={(event) => putCommentLike(reply.id, event)}
                                      >
                                        <i className='iconfont icon-dianzan'></i><b className='praise_num'>{reply.praise}</b>
                                        <div className='hidedz'>+1</div>
                                      </span>
                                      <span className='reply_btn' onClick={() => showReplyForm(reply.cid, reply.id, reply.name, reply.nick)}>Reply</span>
                                    </div>
                                  </div>
                                  <div id={`${reply.cid}${reply.id}`} className='create_reply_wrap'></div>
                                </Col>
                              ))
                            }
                          </Row>
                        </ListGroup.Item>
                      ))
                    }
                  </ListGroup>
                </InfiniteScroll>
              </div>
            </Container>
          </div>
        </Col>
        <Col lg={{ span: 2, offset: 1 }}>
          <div className='auther_box'>
            <div className='auther_avatar'>
              <Image src={authInfo.avatar} roundedCircle fluid />
              {
                authInfo.vip === 1 &&
                <Image className='vip' src={VipImg} />
              }
            </div>
            <p className='auther_name' title={authInfo.nickname}>{authInfo.nickname}</p>
            <div className='auther_infos'>
              <div className='infos_item'>
                <h4>{authInfo.community}</h4>
                <p>Post</p>
              </div>
              <div className='infos_item'>
                <h4>{authInfo.praise}</h4>
                <p>Like</p>
              </div>
              <div className='infos_item'>
                <h4>{authInfo.browset}</h4>
                <p>View</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Signin show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Detail